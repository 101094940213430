import React from "react"
import styled from "styled-components"

import CartImg from "../images/undraw_shopping_app_flsj.svg"
import SEO from "../components/seo"

const Root = styled.div`
  width: 100%;
  padding: 20px;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
`

const IMG = styled.img`
  width: 30%;
  margin-bottom: 2rem;
`

const LineItemChangePage = () => (
  <>
    <SEO title="Line Item Changed" />
    <Root>
      <IMG src={CartImg} />
      <h1>SHOPPING CART ERROR!</h1>
      <p>
        Sorry about that! One of the items in your cart appears to have changed!
        Please refresh the page and add the item again!
      </p>
    </Root>
  </>
)

export default LineItemChangePage
